import { Button, Dialog, Heading, Modal } from "react-aria-components";

import { AUTO_SHIPPING_PATH } from "../../../../config/site/bestrelocation";
import { CloseIcon } from "../../../client/assets/icons/common/CloseIcon";
import { RightArrowIcon } from "../../../client/assets/icons/common/RightArrowIcon";
import { BaseCompanyLogo } from "../../../components/BaseComponents/BaseCompanyLogo";
import { BaseExitModalProvider } from "../../../components/BaseComponents/BaseExitModalProvider";
import { BaseLinkWrapper } from "../../../components/BaseComponents/BaseLinkWrapper";
import { Rating } from "../../../wizard/components/Rating";
import ExitModalBbbRatingImage from "../../assets/exit-modal-bbb-rating.png";
import ExitModalGoldCoinImage from "../../assets/exit-modal-gold-coin.png";
import ExitModalHeadsetImage from "../../assets/exit-modal-headset.png";
import ExitModalSecurityImage from "../../assets/exit-modal-security.png";
import ExitModalSgtAutoTransportImage from "../../assets/exit-modal-sgt-auto-transport.jpg";
import { ExitModalBestPriceIcon } from "../../assets/ExitModalBestPriceIcon";

export const AutoShippingExitModal = () => (
	<BaseExitModalProvider
		vertical={AUTO_SHIPPING_PATH}
		topCompanySlug="sgt-auto-transport"
		className={({ isEntering, isExiting }) =>
			`tw-fixed tw-inset-0 tw-z-[9999] tw-flex tw-min-h-full tw-items-center tw-justify-center tw-overflow-y-auto tw-bg-black/25 tw-px-4 tw-text-center tw-backdrop-blur ${isEntering ? "tw-duration-300 tw-ease-out tw-animate-in tw-fade-in" : ""} ${isExiting ? "tw-duration-200 tw-ease-in tw-animate-out tw-fade-out" : ""} `
		}
	>
		<Modal
			className={({ isEntering, isExiting }) =>
				`tw-w-full tw-max-w-[328px] tw-overflow-hidden tw-rounded-[12px] tw-bg-white md:tw-max-w-[420px] lg:tw-max-w-[810px] lg:tw-rounded-none ${isEntering ? "tw-duration-300 tw-ease-out tw-animate-in tw-zoom-in-95" : ""} ${isExiting ? "tw-duration-200 tw-ease-in tw-animate-out tw-zoom-out-95" : ""} `
			}
		>
			<Dialog className="tw-relative tw-outline-none">
				{({ close }) => (
					<>
						<Button
							onPress={close}
							aria-label="Dismiss"
							className="tw-absolute tw-right-0 tw-top-0 tw-inline-flex tw-cursor-pointer tw-select-none tw-appearance-none tw-items-center tw-whitespace-nowrap tw-rounded tw-border-none tw-bg-transparent tw-p-2 tw-align-middle tw-text-black tw-outline-none tw-transition-all hover:tw-scale-125 focus-visible:tw-ring focus-visible:tw-ring-blue-500/50 disabled:tw-cursor-not-allowed md:tw-gap-2"
						>
							<CloseIcon className="tw-text-xl" />
						</Button>
						<BaseLinkWrapper extraQueryParams={{ fromExitModal: 1 }}>
							<div className="tw-flex">
								<div className="tw-hidden tw-h-auto tw-max-w-[394px] lg:tw-block">
									<img
										src={ExitModalSgtAutoTransportImage}
										alt="Sgt Auto Transport"
										className="tw-size-full tw-object-cover"
									/>
								</div>
								<div className="tw-flex tw-flex-col tw-items-center tw-px-4 tw-pb-6 tw-pt-8 md:tw-p-8">
									<BaseCompanyLogo
										classNames={{
											picture:
												"tw-mb-4 tw-h-[34px] tw-w-[120px] md:tw-h-[48px] md:tw-w-[174px]",
											image: "tw-size-full tw-object-cover",
										}}
									/>
									<Heading
										slot="title"
										className="tw-mb-2 tw-text-2xl tw-font-bold tw-leading-[normal] tw-tracking-[-0.24px] tw-text-[#131D2B] md:tw-mb-1 md:tw-text-[32px] md:tw-leading-[38px] md:tw-tracking-[-0.64px]"
									>
										Auto Transport Made
									</Heading>
									<div className="tw-mb-6 tw-self-stretch tw-text-base tw-font-normal tw-leading-[normal] tw-text-[#131D2B] md:tw-mb-8 md:tw-text-2xl md:tw-leading-[38px] md:tw-tracking-[-0.48px]">
										Quick, Safe & Affordable
									</div>
									<div className="tw-mb-6 tw-flex tw-gap-2 md:tw-mb-8">
										<div className="tw-flex tw-flex-col tw-items-center tw-gap-2 tw-rounded-lg tw-bg-[#F9F9F9] tw-px-2.5 tw-py-3 tw-shadow-[0px_0px_24px_0px_rgba(88,102,126,0.10),0px_0px_1px_0px_rgba(88,102,126,0.48)] md:tw-p-4">
											<img
												src={ExitModalGoldCoinImage}
												alt="Gold Coin"
												className="tw-size-10 tw-object-cover md:tw-size-11"
											/>
											<p className="tw-text-[10px] tw-font-medium tw-leading-[14px] tw-text-[#2B3440] md:tw-text-xs md:tw-leading-4">
												No upfront payment
											</p>
										</div>
										<div className="tw-flex tw-flex-col tw-items-center tw-gap-2 tw-rounded-lg tw-bg-[#F9F9F9] tw-px-2.5 tw-py-3 tw-shadow-[0px_0px_24px_0px_rgba(88,102,126,0.10),0px_0px_1px_0px_rgba(88,102,126,0.48)] md:tw-p-4">
											<img
												src={ExitModalSecurityImage}
												alt="Security"
												className="tw-size-10 tw-object-cover md:tw-size-11"
											/>
											<p className="tw-text-[10px] tw-font-medium tw-leading-[14px] tw-text-[#2B3440] md:tw-text-xs md:tw-leading-4">
												Full Insurance Coverage
											</p>
										</div>
										<div className="tw-flex tw-flex-col tw-items-center tw-gap-2 tw-rounded-lg tw-bg-[#F9F9F9] tw-px-2.5 tw-py-3 tw-shadow-[0px_0px_24px_0px_rgba(88,102,126,0.10),0px_0px_1px_0px_rgba(88,102,126,0.48)] md:tw-p-4">
											<img
												src={ExitModalHeadsetImage}
												alt="Headset"
												className="tw-size-10 tw-object-cover md:tw-size-11"
											/>
											<p className="tw-text-[10px] tw-font-medium tw-leading-[14px] tw-text-[#2B3440] md:tw-text-xs md:tw-leading-4">
												24/7 Customer Support
											</p>
										</div>
									</div>
									<Button
										className="tw-mb-4 tw-flex tw-w-full tw-items-center tw-justify-center tw-gap-2 tw-rounded-lg tw-bg-[#16A34A] tw-px-4 tw-py-3 tw-text-white tw-transition-colors hover:tw-bg-[#0b7032]"
										onPress={close}
										data-testid="cta-button"
									>
										<span className="tw-text-base tw-font-semibold tw-leading-6 tw-tracking-[-0.16px] md:tw-text-xl md:tw-leading-8">
											Get Free Quote
										</span>
										<RightArrowIcon className="tw-text-2xl" />
									</Button>
									<div className="tw-flex tw-justify-center tw-gap-4">
										<img
											src={ExitModalBbbRatingImage}
											alt="Gold Coin"
											className="tw-h-10 tw-w-auto tw-object-cover"
										/>
										<div>
											<div className="tw-mb-1 tw-flex tw-items-center tw-gap-1.5">
												<div className="tw-text-2xl tw-font-semibold tw-leading-6 tw-tracking-[-0.24px] tw-text-[#131D2B]">
													4.7
												</div>
												<Rating
													score={4.7}
													totalCount={5}
													precision={0.1}
													filledColor="tw-text-[#FB923C]"
													className="tw-h-4 tw-text-base"
												/>
											</div>
											<div className="tw-text-xs tw-font-medium tw-leading-[100%] tw-tracking-[0.18px] tw-text-[#4A5059]">
												23000+ Customer Reviews
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="tw-px-1 tw-pb-1 lg:tw-absolute lg:tw-bottom-8 lg:tw-left-12 lg:tw-p-0">
								<div className="tw-flex tw-justify-center tw-gap-2 tw-rounded-lg tw-bg-[#F0FDF4] tw-py-4 lg:tw-bg-transparent lg:tw-p-0">
									<ExitModalBestPriceIcon className="tw-text-[40px]" />
									<p className="tw-max-w-[216px] tw-text-left tw-text-sm tw-font-medium tw-leading-5 tw-text-[#2B3440] lg:tw-max-w-[250px] lg:tw-text-white">
										Best Price Guaranteed - We Price Match Our Bookings
									</p>
								</div>
							</div>
						</BaseLinkWrapper>
					</>
				)}
			</Dialog>
		</Modal>
	</BaseExitModalProvider>
);
